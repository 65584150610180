/* Styles to cleanup how Form.io datagrid looks  */
.formio-component-form {
  .datagrid-table.table-bordered {
    border: none;
  }

  table.datagrid-table {
    table,
    td,
    th {
      border: none;
    }
    td,
    th {
      padding: 0;
      padding-right: 1.5rem;
      padding-top: 1rem;
    }

    button.formio-button-add-row {
      background-color: black;
      border-color: black;
    }
  }
}

.formio-component-file {
  ul.list-group li.list-group-item div.row :nth-child(3) {
    white-space: nowrap;
  }
}
